import { useRef } from 'react';

import Navbar from "../components/Navbar/index"
import LandingPage from './LandingPage';
import Product from './Product';
import Footer from '../components/Footer';

import "../assets/css/index.css"

function Home() {
    const productRef = useRef(null)

    const handleArrowClick = () => {
        productRef.current.scrollIntoView({
            behavior: 'smooth'
        })
    }

    return (
        <>
            <Navbar />
            <main className="home">
                <LandingPage handleArrowClick={handleArrowClick} />
                <Product ref={productRef} id="product" />
                <Footer />
            </main>
        </>
    )
}

export default Home;