import { forwardRef } from 'react';

import gowagr from "../../assets/images/gowagr.svg"
import arrowcorner from "../../assets/images/arrowcorner.svg"
import gowagrsample from "../../assets/images/gowagrsample.svg"
import twitter from "../../assets/images/t-icon.svg"
import instagram from "../../assets/images/i-icon.svg"
import linkedin from "../../assets/images/l-icon.svg"

import "./product.css"

const Product = forwardRef((props, ref) => {
    return (
        <section {...props} ref={ref} className="product">
            <div className="product-wrapper">
                < h1 className="product-title" > Product.</h1 >
                <div className="product-content">
                    {/* First part */}
                    {/* screens > 768px */}
                    <div className="gowagr">
                        <img src={gowagr} className="gowagr-img" alt="Gowagr logo" />
                        <div className="gowagr-body">
                            <h1 className="gowagr-title">GoWagr</h1>
                            <a className="gowagr-web--link" href='https://www.gowagr.app/' target="_blank">
                                <img src={arrowcorner}
                                    className="arrowcorner"
                                    alt="arrow point to the right corner"
                                />
                                Website
                            </a>
                        </div>
                    </div>
                    {/* screens < 451px */}
                    <div className="gowagr-small">
                        <div className="gowagr-small--column">
                            <img src={gowagr} className="gowagr-img" alt="Gowagr logo" />
                            <h1 className="gowagr-title">GoWagr</h1>
                        </div>
                        <a className="gowagr-web--link" href='https://www.gowagr.app/' target="_blank">
                            <img src={arrowcorner}
                                className="arrowcorner"
                                alt="arrow point to the right corner"
                            />
                            Website
                        </a>
                    </div>
                    {/* End first part */}
                    <div className="gowagr-desc">
                        <p className="gowagr-plarge">
                            GoWagr is a fully functional App with a soft user experience that solves the<br />
                            problem of commitment, security, flexibility and transparency between<br />
                            transacting parties who want to enter any kind of monetary wager. Offering a<br />
                            unique experience and social interactions.
                        </p>
                        <p className="gowagr-psmall">
                            GoWagr is a fully functional App with a soft user experience that solves the
                            problem of commitment, security, flexibility and transparency between
                            transacting parties who want to enter any kind of monetary wager. Offering a
                            unique experience and social interactions.
                        </p>
                        <img
                            src={gowagrsample}
                            className="gowagrsample"
                            alt="gowagr app image"
                        />
                        <div className="social-links">
                            <a href="https://twitter.com/gowagr?s=21&t=mbOu8hWED6yZ9J_E_XJmpw" target="_blank" className="social-circle">
                                <img className="social-icon" src={twitter} alt="twitter icon" />
                            </a>
                            <a href="#" className="social-circle">
                                <img className="social-icon" src={instagram} alt="instagram icon" />
                            </a>
                            <a href="https://www.linkedin.com/company/gowagr/" target="_blank" className="social-circle">
                                <img className="social-icon" src={linkedin} alt="linkedin icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div >
            <p className="pof">Proof of work</p>
        </section >
    )
})
export default Product;