import React from 'react'

import "./footer.css"

function Footer() {
    return (
        <section className='footer'>
            <div className="footer-info">
                <p>Get in touch</p>
                <h1>hello@trence.io</h1>
                <h1>+234 905 624 6981</h1>
            </div>
        </section>
    )
}

export default Footer;