import arrowdown from "../../assets/images/arrow.svg"

import "./landingpage.css"

const LandingPage = (props) => {

    return (
        <main className='landingpage'>
            <div className="landingpage-wrapper">
                <div className="landingpage-text">
                    <h1>Building cool<br /> solutions that make <br /> a difference.</h1>
                    <p><span className="pop">📍</span>Dover, DE 19904, Delaware, USA.</p>
                </div>
                <div className="arrow-right">
                    <img onClick={props.handleArrowClick} src={arrowdown} className="bounce" alt="arrow" />
                </div>
            </div>
        </main>
    )
}

export default LandingPage;