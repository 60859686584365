import React from 'react';
import { Link } from "react-router-dom"

import logo from "../../assets/images/logo.svg";

import "./navbar.css"

function Navbar() {
    return (
        <nav className='nav'>
            <div className='header'>
                <Link to="/" className='header-link'>
                    <img src={logo} alt="trence logo" className="trence-logo" />
                    <h1 className="trence">trence.</h1>
                </Link>
            </div>
            {/* <p className='hrz-line'></p> */}
        </nav>
    )
}

export default Navbar;